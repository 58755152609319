<template>
  <div>
    <div>
      <ConnectWallet />
    </div>

    <div class="z-0 font-sans" v-if="getToken == 'false'">
      <div
        class="bg-cover h-screen w-screen bg-center flex items-center justify-center"
        :style="' background-image: url(' + obj_model.img + ')'"
      >
        <div class="text-center">
          <h2
            class="max-w-lg mb-6 font-sans text-xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto text-white"
          >
            @{{ obj_model.nickname }}
          </h2>
          <br />
          <h2
            class="font-sans text-3xl font-bold sm:text-8xl md:mx-auto text-aaa"
          >
            {{ obj_model.name }}
          </h2>
        </div>
      </div>

      <div class="w-full">
        <div class="grid grid-cols-1 lg:grid-cols-2">
          <div
            :class="
              'flex items-center justify-center ' + obj_model.bg_color_about
            "
          >
            <div class="p-4">
              <div v-if="obj_model.contract_address != ''">
                <div class="">
                  <div class="flex justify-center">
                    <img src="@/assets/gif/success.gif" class="w-14" />

                    <h2 class="font-sans text-3xl font-bold sm:text-4xl">
                      {{ obj_model.name }}
                    </h2>
                  </div>
                </div>
                <div class="text-center">
                  <h2
                    class="font-sans text-3xl font-bold sm:text-3xl md:mx-auto"
                  >
                    {{ obj_model.description_nft }}
                  </h2>
                </div>

                <div class="mt-5">
                  <div class="flex">
                    <div class="flex-none">
                      <h2
                        class="font-sans text-xl sm:text-2xl font-bold md:mx-auto"
                      >
                        Name:
                      </h2>
                    </div>

                    <div class="flex-grow text-right">
                      <a
                        :href="
                          'https://bscscan.com/token/' +
                          obj_model.contract_address
                        "
                        target="_blank"
                        class="text-white font-sans underline"
                      >
                        {{ obj_model.coin_name }}
                      </a>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="flex-none">
                      <h2
                        class="font-sans text-xl sm:text-2xl font-bold md:mx-auto"
                      >
                        Contract Address:
                      </h2>
                    </div>

                    <div class="flex-grow text-right">
                      <a
                        :href="
                          'https://bscscan.com/token/' +
                          obj_model.contract_address
                        "
                        target="_blank"
                        class="text-white font-sans underline"
                        >{{ obj_model.contract_address.slice(0, 9) }}...{{
                          obj_model.contract_address.slice(-8)
                        }}</a
                      >
                    </div>
                  </div>

                  <div class="flex">
                    <div class="flex-none">
                      <h2
                        class="font-sans text-xl sm:text-2xl font-bold md:mx-auto"
                      >
                        Token ID:
                      </h2>
                    </div>

                    <div class="flex-grow text-right">
                      <a
                        :href="obj_model.ipfs"
                        target="_blank"
                        class="text-white font-sans underline"
                        >{{ obj_model.token_id }}</a
                      >
                    </div>
                  </div>

                  <div class="flex">
                    <div class="flex-none">
                      <h2
                        class="font-sans text-xl sm:text-2xl font-bold md:mx-auto"
                      >
                        Token Standard:
                      </h2>
                    </div>

                    <div class="flex-grow text-right">
                      <b class="text-white font-sans">{{
                        obj_model.token_standard
                      }}</b>
                    </div>
                  </div>

                  <div class="flex">
                    <div class="flex-none">
                      <h2
                        class="font-sans text-xl sm:text-2xl font-bold md:mx-auto"
                      >
                        Chain:
                      </h2>
                    </div>

                    <div class="flex-grow text-right">
                      <b class="text-white font-sans">{{ obj_model.chain }}</b>
                    </div>
                  </div>

                  <div class="flex">
                    <div class="flex-none">
                      <h2
                        class="font-sans text-xl sm:text-2xl font-bold md:mx-auto"
                      >
                        Chain ID:
                      </h2>
                    </div>

                    <div class="flex-grow text-right">
                      <b class="text-white font-sans">{{
                        obj_model.chain_id
                      }}</b>
                    </div>
                  </div>

                  <div class="flex mt-3">
                    <img
                      src="https://uploads-ssl.webflow.com/5f9a1900790900e2b7f25ba1/60f6bf5aa39a043929bba1a1_Metamask.png"
                      class="h-9"
                    />

                    <a
                      :href="
                        'https://bscscan.com/token/' +
                        obj_model.contract_address
                      "
                      target="_blank"
                      class="ml-2"
                    >
                      <img
                        src="https://bscscan.com/images/logo-bscscan.svg?v=0.0.3"
                        class="h-9"
                      />
                    </a>
                  </div>
                </div>
              </div>

              <h2
                class="max-w-2xl text-xl font-bold tracking-tight sm:text-3xl md:mx-auto mt-10 text-transparent bg-clip-text bg-gradient-to-r from-gray-100 to-yellow-200 mt-10"
              >
                {{ obj_model.about_1 }}
              </h2>
              <br />

              <h2
                class="max-w-2xl font-sans text-lg tracking-tight sm:text-2xl md:mx-auto text-white mt-3"
              >
                {{ obj_model.about_2 }}
              </h2>

              <br />

              <div class="text-center">
                <hr class="h-px my-4 border-yellow-100 border" />

                <h2
                  class="max-w-2xl font-sans text-lg leading-none tracking-tight sm:text-2xl md:mx-auto text-white"
                >
                  {{ obj_model.age }}
                </h2>
                <hr class="h-px my-4 border-yellow-100 border" />

                <h2
                  class="max-w-2xl font-sans text-lg leading-none tracking-tight sm:text-2xl md:mx-auto text-white"
                >
                  {{ obj_model.blood_type }}
                </h2>
                <hr class="h-px my-4 border-yellow-100 border" />

                <h2
                  class="max-w-2xl font-sans text-lg leading-none tracking-tight sm:text-2xl md:mx-auto text-white"
                >
                  {{ obj_model.height }}
                </h2>
                <hr class="h-px my-4 border-yellow-100 border" />

                <h2
                  class="max-w-2xl font-sans text-lg leading-none tracking-tight sm:text-2xl md:mx-auto text-white"
                >
                  {{ obj_model.Chest }}
                </h2>

                <hr class="h-px my-4 border-yellow-100 border" />
                <br />
              </div>
            </div>
          </div>
          <div class="flex justify-center">
            <img :src="obj_model.about_img" class="w-full h-full" />
          </div>
        </div>
      </div>

      <div class="w-full bg-black pt-24 pb-24">
        <div
          class="mx-auto sm:max-w-2xl lg:max-w-screen-lg md:px-24 lg:px-8 text-white p-4"
        >
          <div class="grid grid-cols-1 gap-2 lg:grid-cols-2">
            <div>
              <div class="grid grid-cols-2 gap-1 lg:grid-cols-2">
                <div>
                  <img
                    :src="obj_model.followers_img"
                    class="h-40 w-40 lg:w-56 lg:h-56 rounded-full object-cover ease-in-out"
                  />
                </div>
                <div class="h-full w-full flex items-center">
                  <div>
                    <h2
                      class="max-w-2xl font-sans text-lg tracking-tight sm:text-2xl md:mx-auto text-white"
                    >
                      @{{ obj_model.nickname }}
                    </h2>

                    <h2
                      class="max-w-2xl font-sans text-4xl tracking-tight md:mx-auto text-white mt-3"
                    >
                      {{ obj_model.followers }}
                      <b class="text-sm">followers </b>
                    </h2>
                    <a
                      :href="obj_model.instagram"
                      target="_blank"
                      class="inline-block p-px bg-gradient-to-t from-gray-300 via-green-600 to-gray-300 rounded-xl mt-5"
                    >
                      <div
                        class="inline-block text-xl px-2 py-1 sm:px-4 sm:py-2 border-none rounded-xl text-gray-200 bg-black cursor-pointer hover:bg-gray-200 hover:text-gray-900"
                      >
                        Follow
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="h-full w-full flex items-center">
              <div>
                <h2
                  class="text-lg tracking-tight sm:text-xl md:mx-auto text-white mt-6"
                >
                  {{ obj_model.text_followers }}
                </h2>

                <div class="mt-5">
                  <div class="flex">
                    <a
                      v-if="obj_model.twitter != ''"
                      class="m-2 cursor-pointer"
                      href=""
                      target="_blank"
                    >
                      <i class="fab fa-twitter" style="font-size: 40px"></i>
                    </a>

                    <a
                      v-if="obj_model.reddit != ''"
                      class="m-2 cursor-pointer"
                      href=""
                      target="_blank"
                    >
                      <i class="fab fa-reddit" style="font-size: 40px"></i>
                    </a>

                    <a
                      v-if="obj_model.youtube != ''"
                      class="m-2 cursor-pointer"
                      href=""
                      target="_blank"
                    >
                      <i class="fab fa-youtube" style="font-size: 40px"></i>
                    </a>

                    <a
                      v-if="obj_model.instagram != ''"
                      class="m-2 cursor-pointer"
                      :href="obj_model.instagram"
                      target="_blank"
                    >
                      <i class="fab fa-instagram" style="font-size: 40px"></i>
                    </a>

                    <a
                      v-if="obj_model.facebook != ''"
                      class="m-2 cursor-pointer"
                      href=""
                      target="_blank"
                    >
                      <i class="fab fa-facebook" style="font-size: 40px"></i>
                    </a>

                    <a
                      v-if="obj_model.tiktok != ''"
                      class="m-2 cursor-pointer"
                      href=""
                      target="_blank"
                    >
                      <img
                        src="https://yt3.googleusercontent.com/7cLwBKEif2gYhhBY25ySrTbj1sLeT62tRAIp02Vi8YI6PnWvssT2aivpKojouMeTuo3MyBE3jUU=s900-c-k-c0x00ffffff-no-rj"
                        class="w-10"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="bg-pink-300 w-full flex justify-center items-center pt-40 pb-48"
      >
        <div class="text-center">
          <h2
            class="font-sans text-3xl font-bold sm:text-6xl md:mx-auto text-white"
          >
            {{ obj_model.name }}
          </h2>
          <h2
            class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto text-white"
          >
            @{{ obj_model.nickname }}
          </h2>
        </div>
      </div>

      <div
        class="mx-auto sm:max-w-xl lg:max-w-screen-lg md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <div class="-mt-48 flex justify-center">
          <img
            :src="obj_model.img_show"
            class="inset-0 object-cover ease-in-out"
          />
        </div>
      </div>

      <div
        class="mx-auto sm:max-w-xl lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <div class="">
          <hr class="h-px my-4 border-pink-400 border" />

          <h2
            class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto text-transparent bg-clip-text bg-gradient-to-r from-pink-800 to-pink-400"
          >
            VIRTUAL INFLUENCERS
          </h2>
          <hr class="h-px my-4 border-pink-400 border" />
        </div>

        <div class="grid grid-cols-1 gap-2 lg:grid-cols-2 md:grid-cols-2">
          <div>
            <div
              class="transition hover:-translate-y-1 cursor-pointer rounded-sm rounded"
              style="margin-top: 10px"
              v-for="img in obj_model.img_list_1"
              :key="img"
            >
              <a>
                <img :src="img" alt="Image 1" class="h-full w-full" />
              </a>
            </div>
          </div>

          <div>
            <div
              class="transition hover:-translate-y-1 cursor-pointer rounded-sm rounded"
              style="margin-top: 10px"
              v-for="img in obj_model.img_list_2"
              :key="img"
            >
              <a>
                <img :src="img" alt="Image 1" class="h-full w-full" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import Footer from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      model: this.$route.params.model || null,
      obj_model: {},
    };
  },
  components: {
    ConnectWallet,
    Footer,
  },
  computed: mapGetters(["getModels", "getToken"]),

  methods: {},
  created() {
    var model = this.getModels.filter((m) => {
      return m.nickname == this.model;
    });

    this.obj_model = model[0];
  },
};
</script>

<style scoped></style>
